 <template>
  <div class="product-management" ref="productManagement">
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="72px"
      >
        <el-form-item label="产品名称：">
          <el-input v-model.trim="form.name" placeholder="请输入产品名称"></el-input>
        </el-form-item>
        <el-form-item label="品牌：">
          <el-select v-model="form.brandId" clearable placeholder="请选择品牌">
            <el-option
              v-for="(item,key) in brandList"
              :key="key"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品分类：">
          <categorySelect @getValue="getCategoryId" />
        </el-form-item>
        <el-form-item label="所属公司：">
          <companySelect @getValue="getGroupId" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="onSearch">查询</el-button>
          <el-button type="primary" size="small" @click="onAddProduct">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%"
      stripe
      :height="tableHeight"
    >
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column prop="id" label="产品编号" width="180" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="name" label="产品名称" width="120"></el-table-column>
      <el-table-column
        prop="deptId"
        label="产品品牌"
        :formatter="filterBrand"
        width="120"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="price" label="价格"></el-table-column>
      <el-table-column prop="sale" label="是否在售">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            :sort="scope.row.status === 0 ? 'primary' : 'danger'"
          >{{ scope.row.status === 0 ? "在售" : "下架" }}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="dictUnit" label="产品单位"></el-table-column>
      <el-table-column prop="dictUnitLabel" label="产品单位值" width="120"></el-table-column>
      <!-- <el-table-column prop="picUrls" label="商品主图地址" width="120"></el-table-column> -->
      <el-table-column prop="traceTitle" label="溯源标题"></el-table-column>
      <!-- <el-table-column prop="traceCode" label="溯源码"></el-table-column> -->
      <el-table-column prop="description" label="描述" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="date" label="是否锁定产品状态" width="150">
        <template slot-scope="scope">
          <el-switch
            @change="changeStatus($event,scope.row)"
            v-model="scope.row.status"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" fixed="right">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit-outline"
            type="text"
            size="small"
            sort="primary"
            @click="onEditProduct(scope.row)"
          >编辑</el-button>
          <el-button
            icon="el-icon-delete"
            type="text"
            size="small"
            sort="danger"
            @click="onDeleteProduct(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <el-dialog :title="title" v-if="dialogVisible" :visible.sync="dialogVisible" width="60%">
      <div>
        <div class="body"></div>
        <addProduct ref="addProduct" :productId="productId" @onDialogClose="onDialogClose" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="onSave"
        >{{ title.indexOf("新增") !== -1 ? "新增" : "保存"}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

  <script>
import addProduct from "./components/addProduct";
import companySelect from "@/components/treeSelect/companySelect.vue";
import categorySelect from "@/components/treeSelect/categorySelect.vue";

import {
  getProdsPage,
  deleteProdsById,
  updateProdsStatus,
  getProdsBrandsPage,
} from "@/api/lib/api.js";
export default {
  name: "productManagement",
  components: {
    addProduct,
    categorySelect,
    companySelect,
  },
  data () {
    return {
      tableHeight: 0,
      total: 0,
      tableData: [],
      form: {
        brandId: "",
        cid: "",
        deptId: "",
        name: "",
        current: 1,
        size: 10,
      },
      multipleSelection: [],
      title: "",
      categoryId: null,
      productId: null,
      dialogVisible: false,
      brandList: [],
    };
  },
  methods: {
    /**获取企业id */
    getGroupId (v) {
      this.form.deptId = v;
    },
    /**获取分类id */
    getCategoryId (v) {
      this.form.cid = v;
    },
    // 计算表格高度
    computeHeight () {
      let wholeHeight = this.$refs.productManagement.clientHeight;
      let formHeight = this.$refs.form.clientHeight;
      let paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight - 16 - formHeight - paginationHeight - 10 - 16 - 35;
    },
    // 点击查询
    onSearch () {
      this.form.current = 1;
      this.getProductListByField(this.form);
    },
    //获取数据
    getProductListByField (data) {
      getProdsPage(data).then((res) => {
        if (res.code === 0) {
          this.tableData = res.data.records;
          this.total = res.data.total;
          if (!res.data.records.length && this.form.current !== 1) {
            //如果当前页的数据为0则设置页面为前一页 但是确保当前页不为1
            self.form.current -= 1;
            self.getProductListByField(this.form);
          }
        } else {
          this.tableData = [];
          this.total = 0;
          this.$message.error(res.message);
        }
      });
    },
    //新增
    onAddProduct () {
      this.title = "新增产品";
      this.productId = "";
      this.dialogVisible = true;
    },

    // 修改产品
    onEditProduct (row) {
      this.title = "编辑产品";
      this.productId = row.id;
      this.dialogVisible = true;
    },
    // 修改产品状态
    changeStatus (e, row) {
      let message = e === 1 ? "锁定" : "启用";
      updateProdsStatus({ id: row.id, status: String(e) }).then((res) => {
        if (res.code === 0) {
          this.$message.success(`${message}成功`);
        } else {
          this.$message.error(`${message}失败`);
          row.status = e === 1 ? 0 : 1;
          console.log(111, row);
        }
      });
    },
    //删除
    onDeleteProduct (row) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [
          h("span", null, "确定删除产品 "),
          h("i", { style: "color: #059370" }, row.name),
          h("span", null, " ？"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          deleteProdsById(row.id).then((res) => {
            if (res.code === 0) {
              this.$message.success("删除成功");
              this.getProductListByField(this.form);
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "已取消删除",
          });
        });
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.current = page;
      this.getProductListByField(this.form);
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.size = size;
      this.getProductListByField(this.form);
    },
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    // 弹窗关闭时的回调
    onDialogClose (flag) {
      this.dialogVisible = false;
      if (flag) {
        this.getProductListByField(this.form);
      }
    },
    onSave () {
      this.$refs.addProduct.onSave();
    },
    getProdsBrands () {
      getProdsBrandsPage({
        current: 1,
        size: 100,
      }).then((res) => {
        if (res.code === 0) {
          this.brandList = res.data.records;
        } else {
          this.brandList = [];
          this.$message.error(res.message);
        }
      });
    },
    filterBrand (row) {
      let content = this.brandList.find((res) => res.id === row.brandId);
      return content ? content.name : "";
    },
  },
  created () {
    this.onSearch();
    this.getProdsBrands();
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>
<style lang="scss" scoped>
.product-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  .avatarImg {
    vertical-align: middle;
    border-radius: 50%;
  }
}

.theme-light {
  .product-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>