<template>
  <el-select ref="select_element" :value="valueTitle" :clearable="clearable" @clear="clearHandle">
    <el-option :value="valueTitle" :label="valueTitle" class="options">
      <el-tree
        class="soloTree"
        id="tree-option"
        ref="selectTree"
        :accordion="accordion"
        :data="companyTree"
        :props="props"
        :node-key="props.value"
        :default-expanded-keys="defaultExpandedKey"
        @node-click="handleNodeClick"
      ></el-tree>
    </el-option>
  </el-select>
</template>

<script>
/**
 * 产品分类树   单选
 */
import { getProdsCateTree } from "@/api/lib/api.js";

export default {
  name: "el-tree-select",

  props: {
    /* 配置项 */
    props: {
      type: Object,
      default: () => {
        return {
          value: "id", // ID字段名
          label: "name", // 显示名称
          children: "children" // 子级字段名
        };
      }
    },

    /* 初始值 */
    value: {
      type: [Number, String],
      default: () => {
        return null;
      }
    },
    /* 初始显示 */
    valueName: {
      type: String,
      default: () => {
        return null;
      }
    },
    /* 可清空选项 */
    clearable: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    /* 自动收起 */
    accordion: {
      type: Boolean,
      default: () => {
        return true;
      }
    }
  },

  created () {
    //获取公司树形结构
    getProdsCateTree({}).then(res => {
      this.companyTree = res.data;
      this.initHandle(res);
    });
  },

  data () {
    return {
      companyTree: [],/* 树形数据 */
      valueId: this.value, // 初始值
      valueTitle: "",
      defaultExpandedKey: []
    };
  },

  methods: {
    //清空值
    reset () {
      this.valueId = null;
      this.valueTitle = null;
    },
    // 初始化值
    initHandle (res) {
      if (this.valueId) {
        this.$refs.selectTree.setCurrentKey(this.value); // 设置默认选中
        this.$nextTick(() => {
          this.defaultExpandedKey = [this.valueId]; // 设置默认展开
          this.valueTitle = this.valueName ? this.valueName : this.$refs.selectTree.getNode(this.valueId).data[this.props.label]; // 初始化显示
        })
      } 
      this.$emit("getValue", this.valueId);
      this.$emit("getName", this.valueTitle);
      this.initScroll();
    },
    // 初始化滚动条
    initScroll () {
      this.$nextTick(() => {
        let scrollWrap = document.querySelectorAll(
          ".el-scrollbar .el-select-dropdown__wrap"
        )[0];
        let scrollBar = document.querySelectorAll(
          ".el-scrollbar .el-scrollbar__bar"
        );
        scrollWrap.style.cssText =
          "margin: 0px; max-height: none; overflow: hidden;";
        scrollBar.forEach(ele => (ele.style.width = 0));
      });
    },
    // 切换选项
    handleNodeClick (node) {
      this.valueTitle = node[this.props.label];
      this.valueId = node[this.props.value];
      this.$emit("getValue", this.valueId);
      this.$emit("getName", this.valueTitle);
      this.defaultExpandedKey = [];

      this.$refs.select_element.blur();
    },
    // 清除选中
    clearHandle () {
      this.valueTitle = "";
      this.valueId = null;
      this.defaultExpandedKey = [];
      this.clearSelected();
      this.$emit("getValue", null);
      this.$emit("getName", null);
    },
    /* 清空选中样式 */
    clearSelected () {
      let allNode = document.querySelectorAll("#tree-option .el-tree-node");
      allNode.forEach(element => element.classList.remove("is-current"));
    }
  },

  watch: {
    value () {
      this.valueId = this.value;
      this.initHandle();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 0;
  overflow: hidden;
  overflow-y: auto;
}
.el-select-dropdown__item.selected {
  font-weight: normal;
}
ul li >>> .el-tree .el-tree-node__content {
  height: auto;
  padding: 0 20px;
}
.el-tree-node__label {
  font-weight: normal;
}
.soloTree >>> .is-current > .el-tree-node__content > .el-tree-node__label {
  color: #059370;
  font-weight: 700;
}
.soloTree >>> .is-current .el-tree-node__children .el-tree-node__label {
  /* color: #059370; */
  font-weight: normal;
}
</style>
