<template>
  <div class="body add-product-form">
    <el-form
      :model="addForm"
      :rules="rules"
      ref="addForm"
      label-width="82px"
      label-position="left"
      class="demo-form dialog-form dialog-form-inline"
    >
      <!-- 产品名称： -->
      <el-form-item label="产品名称：" prop="name">
        <el-input v-model.trim="addForm.name" placeholder="请输入产品名称"></el-input>
      </el-form-item>
      <!-- 产品单位： -->
      <el-form-item label="产品单位：" prop="dictUnit">
        <el-select v-model="addForm.dictUnit" @change="onUnitChange" placeholder="请选择产品单位">
          <el-option
            v-for="(item,key) in dictUnitList"
            :key="key"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 分类： -->
      <el-form-item label="分类：" prop="cid">
        <categorySelect :value="categoryId" @getValue="getCategoryId" />
      </el-form-item>
      <!-- 所属部门： -->
      <el-form-item label="所属部门：" prop="deptId">
        <companySelect :value="companyId" @getValue="getGroupId" />
      </el-form-item>
      <!-- 品牌ID： -->
      <el-form-item label="品牌：" prop="brandId">
        <el-select v-model="addForm.brandId" clearable placeholder="请选择品牌">
          <el-option v-for="(item,key) in brandList" :key="key" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <!-- 规格： -->
      <el-form-item label="规格：" prop="attrs">
        <el-cascader
          :options="attrList"
          :props="attrProps"
          v-model="addForm.attrs"
          clearable
          ref="attrcascader"
        ></el-cascader>
      </el-form-item>
      <!-- 价格： -->
      <el-form-item label="价格：" prop="price">
        <el-input v-model.trim="addForm.price" placeholder="请输入价格"></el-input>
      </el-form-item>
      <el-form-item label="销售状态：" prop="sale">
        <el-select v-model="addForm.sale" placeholder="请选择销售状态">
          <el-option label="在售" :value="0"></el-option>
          <el-option label="下架" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排序值：" prop="sort">
        <el-input v-model.trim="addForm.sort" placeholder="请输入排序值"></el-input>
      </el-form-item>
      <el-form-item label="溯源标题：" prop="traceTitle">
        <el-input v-model.trim="addForm.traceTitle" placeholder="请输入溯源标题"></el-input>
      </el-form-item>
      <!--  产品描述：-->
      <el-form-item label="产品描述：">
        <el-input v-model.trim="addForm.description" type="textarea"></el-input>
      </el-form-item>
    </el-form>
    <!-- 产品图片： -->
    <p class="from-title">产品图片：</p>
    <div class="form-area photo-area">
      <el-upload
        :action="fileUploadUrl"
        list-type="picture-card"
        :show-file-list="false"
        accept="image/png, image/gif, image/jpg, image/jpeg"
        :headers="fileHeaders"
        :before-remove="handleBeforeRemove"
        :on-success="handleAvatarSuccess"
        ref="upload"
      >
        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
        <i v-else class="el-icon-plus"></i>
      </el-upload>
    </div>
  </div>
</template>
<script>
import { checkName } from "@/common/utils/index.js";
import {
  insertProds,
  updateProds,
  getProdsById,
  getProdsBrandsPage,
  getDictsItemByType,
  getProdsAttrsTree,
  UPLOAD_URL,
  HEADERS,
  IMG_PREFIX,
} from "@/api/lib/api.js";
import companySelect from "@/components/treeSelect/companySelect.vue";
import categorySelect from "@/components/treeSelect/categorySelect.vue";
export default {
  components: {
    companySelect,
    categorySelect,
  },
  props: {
    productId: {
      type: String,
    },
  },
  data() {
    return {
      companyId: null,
      categoryId: null,
      attrId: [],
      addForm: {
        attrs: "",
        cid: "0",
        brandId: "",
        deptId: "",
        description: "",
        dictUnit: "",
        dictUnitLabel: "",
        id: 0,
        name: "",
        picUrls: null,
        price: 5,
        sale: 0,
        sort: 6,
        traceTitle: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入产品名",
            trigger: "blur",
          },
          { validator: checkName, trigger: "blur" },
        ],
        deptId: [{ required: true, message: "请选择企业", trigger: "blur" }],
        attrs: [{ required: true, message: "请选择规格", trigger: "blur" }],
        productId: [{ required: true, message: "请选择品牌", trigger: "blur" }],
        cid: [{ required: true, message: "请选择分类", trigger: "blur" }],
        dictUnit: [
          {
            required: true,
            message: "请输入产品单位",
            trigger: "blur",
          },
        ],
        price: [{ required: true, message: "请输入价格", trigger: "blur" }],
        sale: [
          {
            required: true,
            message: "请选择销售状态",
            trigger: "blur",
          },
        ],
        sort: [
          {
            required: true,
            message: "请输入排序值",
            trigger: "blur",
          },
        ],
        traceTitle: [
          {
            required: true,
            message: "请输入溯源标题",
            trigger: "blur",
          },
        ],
      },
      fileHeaders: "",
      fileUploadUrl: "",
      imageUrl: null,
      isPhotoExceed: false,
      brandList: [],
      dictUnitList: [],
      attrProps: {
        multiple: true,
        expandTrigger: "hover",
        value: "id",
        label: "name",
        children: "values",
        checkStrictly: true,
        emitPath: false,
      },
      attrList: [],
    };
  },
  methods: {
    /**获取企业id */
    getGroupId(v) {
      this.addForm.deptId = v;
    },
    /**获取分类id */
    getCategoryId(v) {
      this.addForm.cid = v;
    },
    /**获取规格id */
    getAttrId(v) {
      this.addForm.attrs = v.join(",");
    },

    // 移除照片确认
    handleBeforeRemove(file, fileList) {
      // getProdsById({ fileName: file.response ? file.response.data : file.url }).then(res => {
      //   if (res.code === 1000) {
      //     this.addForm.photos.forEach((item, index) => {
      //       if (file.response) {
      //         if (item === file.response.data) {
      //           this.addForm.photos.splice(index, 1)
      //         }
      //       } else {
      //         if (item.url === file.url) {
      //           this.addForm.photos.splice(index, 1)
      //         }
      //       }
      //     })
      //   }
      // })
    },
    // 成功添加照片
    handleAvatarSuccess(res, file) {
      // console.log(res)
      console.log(this.$refs.upload);

      if (res.code === 0) {
        this.imageUrl = IMG_PREFIX + res.data.url;
        this.addForm.picUrls = res.data.url;
      }
    },
    // 上传接口
    uploadUrl() {
      return;
    },
    // 上传请求头
    getHeader() {
      return {
        Authorization: localStorage.getItem("token"),
        token: localStorage.getItem("token"),
      };
    },
    // 新增/保存产品
    onSave() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          let attrString = JSON.stringify(this.addForm.attrs);
          let data = {
            name: this.addForm.name,
            deptId: this.addForm.deptId,
            attrs: attrString,
            brandId: this.addForm.brandId,
            cid: this.addForm.cid,
            dictUnit: this.addForm.dictUnit,
            dictUnitLabel: this.addForm.dictUnitLabel,
            price: this.addForm.price,
            sale: this.addForm.sale,
            sort: this.addForm.sort,
            traceTitle: this.addForm.traceTitle,
            description: this.addForm.description,
            picUrls: this.addForm.picUrls,
          };
          if (this.productId === "") {
            insertProds(data).then((res) => {
              if (res.code === 0) {
                this.$message.success("新增成功");
              } else {
                this.$message.error("新增失败");
              }
              this.$emit("onDialogClose", res.code === 0);
            });
          } else {
            data = {
              ...data,
              id: this.addForm.id,
            };
            updateProds(data).then((res) => {
              if (res.code === 0) {
                this.$message.success("修改成功");
              } else {
                this.$message.error("修改失败");
              }
              this.$emit("onDialogClose", res.code === 0);
            });
          }
        }
      });
    },
    getProdsBrands() {
      getProdsBrandsPage({
        current: 1,
        size: 100,
      }).then((res) => {
        if (res.code === 0) {
          this.brandList = res.data.records;
        } else {
          this.brandList = [];
          this.$message.error(res.message);
        }
      });
    },
    getDictsItemByType() {
      getDictsItemByType("unit_type").then((res) => {
        if (res.code === 0) {
          this.dictUnitList = res.data;
        } else {
          this.dictUnitList = [];
          this.$message.error(res.message);
        }
      });
    },
    //获取规格属性
    getProdsAttrsTree() {
      getProdsAttrsTree({}).then((res) => {
        this.attrList = res.data;
      });
    },
    //
    onUnitChange(value) {
      let labelTo = this.dictUnitList.find((item) => {
        return (item.value = value);
      });
      if (labelTo) {
        this.addForm.dictUnitLabel = labelTo.label;
      }
    },
  },
  created() {
    this.fileHeaders = HEADERS;
    this.fileUploadUrl = UPLOAD_URL;
    this.getProdsBrands();
    this.getDictsItemByType();
    this.getProdsAttrsTree();
    if (this.productId) {
      getProdsById(this.productId).then((res) => {
        if (res.code === 0) {
          this.addForm = { ...res.data };
          delete this.addForm.createBy;
          delete this.addForm.createTime;
          delete this.addForm.status;
          delete this.addForm.updateBy;
          delete this.addForm.updateTime;
          this.companyId = res.data.deptId;
          this.categoryId = res.data.cid;
          this.attrId = res.data.attrs.split(",");
          this.addForm.attrs = JSON.parse(res.data.attrs);
          if (res.data.picUrls) {
            this.imageUrl = IMG_PREFIX + res.data.picUrls;
          }
        }
      });
    }
  },
  mounted() {},
  watch: {
    "addForm.photos"(val) {
      if (val.length > 2) {
        this.isPhotoExceed = true;
      } else {
        this.isPhotoExceed = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.add-product-form {
  @include themify() {
    .from-title {
      font-size: 14px;
      color: themed("n7");
      margin: 10px 0 10px;
    }
    .photo-area {
      padding-bottom: 16px;
    }
  }
}
</style>
<style lang="scss">
.el-cascader-panel {
  .el-cascader-menu:first-child {
    ul {
      li {
        label.el-checkbox {
          display: none;
        }
      }
    }
  }
}
.avatar {
  height: 100%;
  width: 100%;
}
</style>
